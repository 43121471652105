export default {
  data: function () {
    return {
      // appColorTabDark: "#7f561b"
    }
  },
  computed: {
    appColorTabDark: function() {
      return "#7f561b"
    },
  },
}
